import classnames from 'classnames';
import Spinner from 'react-svg-spinner';

import { Link } from '@/components/link';

import { isLink } from '@/helpers/storyblok/is-link';

import './index.css';

delete Spinner.defaultProps;

/**
 * @param {object} props - Props for the button.
 * @param {'primary'|'secondary'|'light-grey'|'transparent'|'black' | 'full-black'|'blue'} props.theme - The theme of the button.
 * @param {'xsmall'|'small'|'normal'|'large'} props.height - The height of the button.
 */
export const Button = ({
  as = 'button',
  link = null,
  impacting = false,
  theme = 'primary',
  height = 'normal',
  rightIcon = null,
  loading = false,
  children,
  dangerouslySetInnerHTML,
  disabled = false,
  onClick,
  className = '',
  ...rest
}) => {
  const El = isLink(link) ? Link : as;

  return (
    <div
      className={classnames(
        'button',
        `button--theme-${theme}`,
        `button--height-${height}`,
        {
          'button--has-right-icon': rightIcon,
          'button--impacting': impacting,
          'button--disabled': disabled,
          'button--loading': loading,
        },
        className,
      )}
    >
      <span className="button__background" />

      <span className="button__content">
        <El
          className="button__text"
          link={El === Link ? link : null}
          disabled={El === 'button' ? disabled : undefined}
          onClick={onClick}
          {...rest}
          {...(children ? { children } : { dangerouslySetInnerHTML })}
        />

        {rightIcon && !loading ? (
          <span className="button__icon">{rightIcon}</span>
        ) : null}

        <span className="button__spinner">
          <Spinner color="white" speed="fast" size="16" gap={4} thickness={4} />
        </span>
      </span>
    </div>
  );
};
